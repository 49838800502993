.root {
  background-color: rgba(19, 19, 19, 0.745);
  position: relative;
  min-width: 250px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  overflow: hidden;
  text-decoration: none;
}
.root:nth-child(2n + 1) {
  margin-left: auto;
}
.unlock:hover {
  scale: 1.02;
}

.root p {
  color: #fff;
}

.cover {
  display: block;
  width: 100%;
}

.locker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
}
.locker h1 {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 3;
  color: #828282;
}

.blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.slick {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.container {
  padding: 20px 20px;
}
.container h2 {
  text-align: left;
  font-size: 15px;
  color: #d5d5d5;
  line-height: 1.5;
}
.container h1 {
  background: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 900;
}

.description {
  margin-top: 40px;
}
.description p {
  font-size: 15px;
  line-height: 1.5;
  margin: 15px 0;
}

.container ul {
  margin: 0;
  list-style-image: url("./assets/Marker.png");
  padding-left: 20px;
}
.container li {
  line-height: 1.5;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
}
.info p {
  margin: 0;
}
.info .available p:last-child,
.info .duration p:last-child {
  color: #d5d5d5;
  line-height: 2;
}
.info .available p:first-child,
.info .duration p:first-child {
  text-transform: uppercase;
  font-weight: 700;
}
.info .available p:first-child,
.info .duration p:first-child span:last-child {
  font-size: 20px;
}
.duration span:first-child {
  font-size: 40px;
  line-height: 0.8;
}
.info .available p:first-child {
  padding-top: 11px;
}
.info .available p:first-child,
.info .duration span {
  background: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.root button {
  background: linear-gradient(#ca0aa5, #5a1c48);
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 20px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.root button:after {
  content: "";
  height: 70%;
  left: -200%;
  opacity: 0;
  position: absolute;
  top: -20%;
  transform: rotate(-50deg);
  width: 350%;
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.244) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
.root button:hover:after {
  left: -50%;
  opacity: 1;
  top: -50%;
  transition-duration: 0.7s, 0.3s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: ease;
}
.root button:active:after {
  opacity: 0;
}

@media screen and (max-width: 1120px) {
  .root {
    margin: 0 auto;
  }
}
