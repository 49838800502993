.root {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
}

.container {
  max-width: 1860px;
  width: 100%;
  padding: 50px;
  margin: 0 auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 80px;
}

.column {
  margin: auto 0;
}

.column:first-child {
  gap: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.column:last-child .gridItem {
  text-align: end;
}

.column:last-child .gridItem span {
  font-size: 12px;
}

.gridItem {
  text-align: center;
  line-height: 1.7;
}

.root ul {
  padding: 0;
}
.root li {
  list-style-type: none;
}

.root ul:first-child img {
  height: 40px;
  width: 40px;
  transition: 0.3s;
}
.root ul:first-child img:hover {
  transform: scale(0.9);
}

.gridItem::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.logo {
  width: 200px;
  cursor: pointer;
}

.policy {
  font-size: 14px;
}
.policy a {
  text-decoration: none;
  color: #ffffff;
}
.policy li {
  line-height: 1.22;
  margin-top: 7px;
}
.policy a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1483px) {
  .column:last-child .gridItem {
    text-align: center;
  }
}
