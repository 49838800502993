.root {
  overflow: hidden;
  background-color: #000000;
  height: 100vh;
  position: relative;
}

.fullScreen {
  position: relative;
  height: 100%;
  transition: 0.3s ease-out;
  opacity: 1;
}

.sliderBtn {
  position: relative;
  position: absolute;
  height: 100px;
  width: 10%;
  max-width: 70px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 2;
  align-items: center;
  transition: 0.2;
}
.sliderBtn:hover {
  background-color: #8989891c;
}

.sliderBtn img {
  width: 40px;
  height: 40px;
  filter: invert(100%);
  opacity: 50%;
  position: absolute;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
}

.sliderBtnRight {
  right: 0;
}

.sliderBtnDone {
  display: none;
}

.fullScreenVideoWrapper {
  position: relative;
  height: 100%;
  background-color: #000000;
}

.fullScreenVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s;
  opacity: 0;
}

.fullScreenVideoDone {
  opacity: 1;
}

.slider {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  overflow: hidden;
  transition: 0.8s;
}

.sliderHide {
  display: none;
}

.slide {
  width: 100vw;
  text-align: center;
}
.slide h2 {
  font-weight: 500;
}

.slideWrapper {
  display: flex;
  align-items: center;
}

.toSlideVideoDone {
  transform: translate(0%, -60%);
}
.toSlideAboutDone {
  transform: translate(-100vw, -60%);
}
.toSlideSocialsDone {
  transform: translate(-200vw, -60%);
}

.introSlideVideo,
.introSlideSocials {
  padding: 0 80px;
}

.introSlideVideo {
  transform: translateY(20%);
  opacity: 0;
  transition: 1s;
}
.introSlideVideoEnterDone {
  opacity: 1;
  transform: translateY(0%);
}

.introSlideVideo img {
  max-width: 550px;
  width: 100%;
}

.introSlideVideo hr {
  min-width: 40px;
  max-width: 60px;
  width: 7%;
  height: 2px;
  background-color: #fff;
  margin: 4vh auto;
  border: none;
}

.introSlideVideo h2 {
  font-style: italic;
}

.introSlideVideo h2,
.introSlideAbout h2,
.introSlideSocials h2 {
  font-size: 16px;
}

.introSlideSocials h2 {
  margin-top: 30px;
}

.introSlideSocials ul {
  list-style-type: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.introSlideSocials img {
  width: 50px;
  transition: 0.2s;
}

.introSlideSocials img:hover {
  scale: 0.9;
  opacity: 0.8;
}

.introSlideSocials a {
  display: block;
}

.introBtns {
  position: absolute;
  top: 75%;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  padding: 0px 20px;
}

.introBtns a {
  min-width: 210px;
  height: 42px;
  display: inline-block;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  margin: 0 10px 15px 10px;
  position: relative;
  overflow: hidden;
}

.introBtnWatchShowReel {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #da206a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(218 32 106 / 0));
  --tw-gradient-to: #ee5c9c;
  color: #ffffff;
  transform: translateX(-10%);
  cursor: none;
  transition: 1s;
  opacity: 0;
}

.introBtnWatchShowReelEnterActiveVisibility {
  transform: translateX(0%);
  opacity: 1;
}
.introBtnWatchShowReelEnterDoneVisibility {
  transform: translateX(0%);
  opacity: 1;
  transition: 0.3s;
}

.introBtnContact {
  color: #1d1d1d;
  background: linear-gradient(#00ff66 0%, #00ffd1 98.96%);
  opacity: 0;
  transform: translateX(-30%);
  transition: 1s;
}
.contactBtnEnterDone {
  background-color: #fff;
}
.introBtnContactEnterActiveVisibility {
  transform: translateX(0%);
  opacity: 1;
}
.introBtnContactEnterDoneVisibility {
  transform: translateX(0%);
  opacity: 1;
  transition: 0.3s;
}

.introBtnWatchShowReel:after,
.introBtnContact::after {
  content: '';
  height: 80%;
  left: -420%;
  opacity: 0;
  position: absolute;
  top: -20%;
  transform: rotate(-50deg);
  width: 350%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.359) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
.introBtnWatchShowReel:hover:after,
.introBtnContact:hover:after {
  left: -50%;
  opacity: 1;
  top: -50%;
  transition: 0.7s ease-out;
  transition-property: left, top, opacity;
  transition-timing-function: ease;
}
.introBtnWatchShowReel:active:after,
.introBtnContact:active:after {
  opacity: 0;
}

.contentController {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  width: 100%;
}

.contentControllerBtn {
  width: 100%;
  line-height: 40px;
  padding: 0px 100px;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  color: #8c8c8c;
  background: linear-gradient(
    270deg,
    rgba(21, 21, 21, 0) -11.55%,
    rgba(20, 20, 20, 0.56) 99.38%
  );
  backdrop-filter: blur(7.5px);
  font-family: 'Roboto';
}

.contentControllerPortfolioDone {
  background: linear-gradient(
    270deg,
    #fa00ce -11.55%,
    rgba(21, 21, 21, 0) 81.3%
  );
  color: #fff;
}
.contentControllerEducationDone {
  background: linear-gradient(
    -270deg,
    #fa00ce -11.55%,
    rgba(21, 21, 21, 0) 81.3%
  );
  color: #fff;
}

.contentController button:first-child {
  text-align: end;
}
.contentController button:last-child {
  text-align: start;
}

.overlay iframe {
  width: 100vw;
  height: calc(100vh - 40px);
  border: none;
}

.overlayCloseBtn {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  margin: 110px 0 0 50px;
  border-radius: 100%;
  cursor: pointer;
}
.overlayCloseBtn:before {
  content: '+';
  color: #fff;
  position: absolute;
  z-index: 2;
  transform: rotate(45deg);
  font-size: 50px;
  line-height: 1;
  top: -5px;
  left: 6px;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
}
.overlayCloseBtn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: rgb(250, 0, 206, 0.8);
  z-index: 1;
  transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 0.85);
  transform: scale(0.01);
}
.overlayCloseBtn:hover:after {
  transform: scale(1);
}
.overlayCloseBtn:hover:before {
  transform: scale(0.8) rotate(45deg);
  color: #fff;
}

/* AUTHOR */

.author {
  padding: 0;
  text-align: 'left';
  margin-top: 10px;
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
  max-width: 1000px;
  width: calc(100% - 180px);
  margin: 0 auto;
}

.author__container {
  display: grid;
  grid-template-areas:
    'image title'
    'image body'
    'image footer';
}

.author h1 {
  text-align: left;
  text-transform: none !important;
  background-image: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0px 0px 30px #3affef9a;
}

.author h2 {
  text-align: left;
  display: block;
}

.author__image {
  padding: 0 !important;
  overflow: hidden;
  grid-area: image;
  min-width: 260px;
  max-height: 100%;
  transform: translateY(0px);
  border-right: 10px solid rgb(67, 8, 8);
}

.author__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.author__title {
  grid-area: title;
  padding: 20px 20px 0 20px;
}

.author__subtitle {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.author__insta {
  display: flex;
  align-items: center;
  gap: 7px;
}

.author__insta p {
  color: grey;
}

.author__body {
  grid-area: body;
  padding: 20px 20px 25px 20px;
  font-size: 18px;
}

.author ul {
  list-style-image: url('./assets/Marker_2.png');
  text-align: left;
  padding-left: 20px;
}
.author li {
  padding-top: 10px;
}

.author li:first-of-type {
  padding-top: 0;
}

.author__footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 30px;
  grid-area: footer;
  padding: 0px 20px 20px 20px;
}

.author__video {
  background-color: rgba(0, 0, 0, 0.282);
}

.author__video img {
  width: 200px;
  display: block;
}

.author__footer p {
  text-align: right;
  max-width: 370px;
}

.author__footer p:first-child {
  font-style: italic;
  line-height: 1.2;
}

.author__footer p:last-child {
  font-weight: 900;
  margin-top: 10px;
}

@media screen and (max-width: 960px) {
  .introSlideAbout {
    padding: 0 60px;
  }
  .introSlideAbout img {
    max-width: 150px;
    max-height: 150px;
    width: 30vw;
    height: 30vw;
    border-radius: 100%;
  }
  .introSlideAbout h2 {
    margin: 15px auto 0 auto;
    max-width: 400px;
  }
  .introBtns {
    top: 80%;
  }
}

@media screen and (max-width: 450px) {
  .slider {
    transform: translateY(-90%);
  }
  .toSlideVideoDone {
    transform: translate(0%, -90%);
  }
  .toSlideAboutDone {
    transform: translate(-100vw, -90%);
  }
  .toSlideSocialsDone {
    transform: translate(-200vw, -90%);
  }
  .introBtns {
    top: 60%;
  }
}
@media screen and (min-width: 520px) {
  .introSlideSocials h2 {
    margin-top: 20px;
  }
  .introSlideSocials div {
    margin-top: 50px;
  }
}
@media screen and (min-width: 620px) {
  .introSlideSocials h2,
  .introSlideVideo h2,
  .introSlideAbout h2 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .introBtns a {
    font-size: 18px;
    min-width: 230px;
  }
}
@media screen and (max-height: 650px) {
  .introSlideVideo img,
  .introSlideAbout img {
    height: 20vh;
  }
  .introSlideAbout img {
    width: 20vh;
  }
  .introSlideSocials h2,
  .introSlideVideo h2,
  .introSlideAbout h2 {
    font-size: 14px;
  }
  .introSlideAbout h2 {
    margin-top: 10px;
  }
}
@media screen and (max-height: 600px) {
  .introSlideVideo img,
  .introSlideAbout img {
    height: 20vh;
  }
  .introSlideSocials h2 {
    font-size: 0px;
  }
  .introBtns a {
    font-size: 14px;
    width: 180px;
    height: 37px;
  }
  .contentController button:first-child,
  .contentController button:last-child {
    line-height: 35px;
    font-size: 18px;
  }
}

@media screen and (max-width: 700px) {
  .contentController button:first-child,
  .contentController button:last-child {
    padding: 0;
    text-align: center;
  }
}

@media screen and ((max-width: 475px) or (max-height: 600px)) {
  .overlayCloseBtn {
    margin: 80px 0 0 20px;
  }
}
