.root{
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    font-weight: 700;
    z-index: 100;
    background: linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,.8) 100%);
}

.wrapper{
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 24px;  
    padding: 35px 50px;
    transition: .5s;
    padding-top: 45px;
}
.wrapperEnterDoneByIntroPlayer{
    background-color: #000000;
}
.wrapperEnterDone{
    padding-top: 35px;
    background-color: #00000000;
}

.logo{
    margin-right: 50px;
    font-weight: 900;
}

.root a{
    cursor: pointer;
    text-decoration: none;
}

.azhypa{
    color:#FFFFFF;
    font-style: italic;
    opacity: 0;
}
.mediaCenter{
    font-style: italic;
    color:#FA00CE;
    opacity: 0;
    padding-left: 10px;
}
.mediaCenter:hover{
    text-shadow: 0px 0px 50px #FA00CE;
}
.azhypaEnterActive{
    opacity: 1;
    transition: 1s;
}
.azhypaEnterDone{
    opacity: 1;
    transition: .3s;
}
.mediaCenterEnterActive{
    opacity: 1;
    transition: 2s;
}
.mediaCenterEnterDone{
    opacity: 1;
    transition: .3s;
}

.nav ul{
    display: flex;
    margin: 0;
    padding: 0;
    gap: 70px;
}

.navItem{
    opacity: 1;
    transition: 1s ease-in-out;
    list-style-type: none;
}

.link{
    transition: .3s;
    color: #FFFFFF;
    text-decoration: none;
    outline: none; 
    opacity: 0;
    transform: translateX(30%);
    display: block;
    text-transform: uppercase;
}
.link:hover{
    color: #FA00CE;
    text-shadow: 0px 0px 50px #FA00CE;    
}

.language{
    cursor: pointer;
    user-select: none;
    transform: translateX(40%);
    opacity: 0;
}
.language span{
    transition: .3s;
}

.linkEnterActive{
    transition: 1s;
    opacity: 1;
    transform: translateX(0%);
}
.linkEnterDone{
    transition: .3s;
    opacity: 1;
    transform: translateX(0%);
}

.gredient{
    opacity: 0;
    background: linear-gradient(0deg, rgba(0,0,0,.5) 10%, rgba(0,0,0,.8) 100%);
    -webkit-backdrop-filter: blur(4px);
    -moz-backdrop-filter: blur(4px);
    -ms-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: -1;
    transition: .5s;
}
.gredientEnterDone{
    opacity: 1;
}

.noScroll{
    overflow: hidden;
}

.navBtnDone{
    color: #FA00CE;
    text-shadow: 0px 0px 50px #FA00CE;
}

.navBtnLanguageDone{
    color: #FA00CE;
}

.burger{
    display: none;
}


@media screen and (max-width: 850px){
    .burger{
        z-index: 5;
        display: block;
        position: relative;
        width: 30px;
        height: 18px;
        cursor: pointer;
        margin-top: 5px;
        opacity: 0;
        transition: 1s;
        transform: translateX(30px);
    }
    .burgerShowEnterDone{
        transform: translateX(0px);
        opacity: 1;
    }
    .burgerEnterDone::before{
        margin-top: 50%;
        transform: rotate(-45deg) translate(0px, -50%);
    }
    .burgerEnterDone::after{
        margin-bottom: 10%;
        transform: rotate(45deg) translate(0px, 50%);
    }
    .burgerIcon{
        top: 50%;
        transform: translate(0px, -50%);
    }
    .burgerIconEnterDone{
        opacity: 0;
    }
    .burgerIcon, .burger::before, .burger::after{
        left: 0;
        position: absolute;
        height: 17%;
        width: 100%;
        transition: all .3s ease 0s;
        background-color: #FFFFFF;
        border-radius: 10px;
    }
    .burger::before, .burger::after{
        content: '';
    }
    .burger::before{
        top: 0;
    }
    .burger::after{
        bottom: 0;
    }
    .nav{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
        opacity: 95%;
        padding: 80px 40px 40px 40px;
        transform: translate(100%);
        transition: .3s ease-out;
        overflow: auto;
    }
    .navEnterDone{
        transform: translate(0%);
    }
    .nav ul{
        margin-top: 4vh;
        padding: 0;
        gap: 50px;
        flex-direction: column;
    }
    .link, .language{
        font-size: 4.5vh;
    }
    .language{
        margin-top: 40px;
    }
}

@media screen and (max-width: 475px){
    .wrapper{
        padding: 25px 20px;
    }
    .logo{
        margin-top: 2px;
        font-size: 20px;
    }
    .gredient{
        height: 76px;
    }
}

@media screen and (max-height: 600px){
    .wrapper{
        padding: 25px 20px;
    }
    .logo{
        margin-top: 2px;
        font-size: 20px;
    }
    .gredient{
        height: 76px;
    }
}

@media screen and (max-width: 360px){
    .logo{
        margin-top: 4px;
        font-size: 16px;
    }
}

@media screen and (max-height: 600px) and (min-width: 767px){
    .link{
        font-size: 20px;
    }
}