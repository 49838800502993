.root {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  background: rgba(48, 48, 48, 0.32);
  backdrop-filter: blur(30px);
  text-shadow: none;
  text-align: center;
}

.root h1 {
  line-height: 1;
}

.root h2 {
  line-height: 1.5;
  margin-top: 10px;
}

.root h3 {
  font-size: 72px;
  font-weight: 900;
  text-shadow: 0px 0px 20px #ffffff5b;
  margin: 0;
}

.content {
  padding: 50px 20px;
}

.oldPrice {
  position: relative;
  display: inline-block;
}

.oldPrice p {
  font-size: 30px;
  color: #9c9999;
  font-weight: 900;
  margin: 0;
  line-height: 1;
}

.oldPrice div {
  position: absolute;
  background-color: #9c9999;
  width: 105%;
  height: 2px;
  top: 50%;
  transform: rotate(-20deg);
}

.price {
  display: inline-block;
  margin: 40px 0;
  position: relative;
}

.discount {
  position: absolute;
  top: 15px;
  left: -60px;
}

.discount img {
  width: 60px;
}

.discount p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}

.root li {
  list-style: inside;
  margin-top: 10px;
  font-weight: bold;
}

.lockedLi {
  color: #9c9999;
  text-decoration: line-through;
}

.root li:first-of-type {
  margin-top: 0px;
}

.root p {
  margin: 0;
}

.info {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin: 40px auto 0 auto;
}

.access {
  margin-top: 15px;
}

.info p:nth-child(2n) {
  font-size: 15px;
}

.access p:first-child,
.duration span:last-child {
  font-size: 18px;
}

.duration span:first-child {
  font-size: 36px;
  font-weight: 900;
}

.duration span:last-child,
.access p:first-child {
  font-weight: 900;
  text-transform: uppercase;
}

.policy {
  font-size: 14px;
  color: #858585;
  margin-bottom: 10px;
}
.policy a {
  color: #b90d95;
}
.policy a:hover {
  color: #c30c9e;
}

.info p {
  line-height: 1;
  text-align: left;
}
.access p:last-child {
  line-height: 1.5;
}

@media screen and (max-width: 510px) {
  .root {
    margin-top: 50px;
  }
  .root h1 {
    font-size: 28px;
    line-height: 1;
  }
  .root h2 {
    font-size: 16px;
    margin-top: 5px;
  }
  .root h3 {
    font-size: 60px;
  }
  .price {
    margin: 30px 0;
  }
  .root button {
    height: 75px;
  }
}
