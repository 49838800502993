.root{
    position: relative;
    width: 100%;
    height: 80px;
    border: none;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: 'Roboto';
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
}
.root:after{
    content: "";
    height: 100%;
    left: -200%;
    opacity: 0;
    position: absolute;
    top: -20%;
    transform: rotate(-50deg);
    width: 350%;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1293767507002801) 50%, rgba(255,255,255,0) 100%);
}
.root:hover:after{
    left: -50%;
    opacity: 1;
    top: -50%;
    transition: 1s, ease-out;
    transition-property: left, top, opacity;
    transition-timing-function: ease;
}
.root:active:after{
    opacity: 0;
}