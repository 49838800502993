.root {
  grid-column: 1 / 4;
  background-color: #0e0e0e;
  z-index: 2;
}

.container {
  position: relative;
  width: 70%;
  margin: 50px auto;
}

h1 {
  text-align: center;
  font-size: 35px;
}

.video {
  position: absolute;
  position: relative;
  padding: 0 0 56.25% 0;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}

.loader {
  margin-top: 50px;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 1.8;
}

.text p span:first-child {
  font-weight: 900;
}
.text p span:last-child {
  font-weight: 400;
}

@media screen and (max-width: 1333px) {
  .root {
    grid-column: 1 / 3;
  }
}

@media screen and (max-width: 520px) {
  .root {
    grid-column: 1 / 2;
  }
}
