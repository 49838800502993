.root h1 {
  font-size: 36px;
}

.root h2 {
  font-size: 18px;
}

.root h1,
.root h2 {
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
}

.wrapper {
  padding: 150px 0 70px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  text-shadow: 0px 0px 20px #ffffff5b;
}

.backButton {
  position: fixed;
  top: 125px;
  left: 50px;
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
}

.backButtonEnterActive {
  opacity: 0;
  transition: 0.3s;
}
.backButtonEnterDone {
  transition: 0.3s;
  opacity: 0;
  display: none;
}

.backButton p {
  margin: 0 3px;
  background: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.backButton img {
  width: 16px;
  height: 25px;
}

/* INTRO */

.intro {
}

.intro__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
  grid-template-areas: 'title cover' 'info cover';
}

.intro__title {
  grid-area: title;
  padding: 20px;
}

.intro__title h1 {
  text-align: left;
  background: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 50px #3affef9a;
}

.intro__title h2 {
  text-align: left;
  margin-top: 5px;
}

.intro__info {
  grid-area: info;
  padding: 20px;
  z-index: 1;
}

.intro__info ul {
  margin: 0;
  padding-left: 20px;
  list-style-image: url('./assets/images/Marker_2.png');
}

.intro__info li {
  font-size: 18px;
  line-height: 20px;
  margin-top: 5px;
}

.intro__bottom {
  margin-top: 40px;
  display: flex;
}

.intro__duration {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
}

.intro__duration span:nth-child(1) {
}
.intro__duration span:nth-child(2) {
  font-size: 48px;
  margin-left: 8px;
}
.intro__duration span:nth-child(3) {
  margin-left: 3px;
}
.intro__duration span:nth-child(4) {
  margin-left: 8px;
}

.intro__duration span:nth-child(2),
.intro__duration span:nth-child(3) {
  background: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 50px #3affef9a;
}

.intro__cover {
  grid-area: cover;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 320px;
  background-image: url(./assets/images/Cover_mobile.jpeg);
  background-position: center;
  background-size: cover;
}

.intro__cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* AUTHOR */

.author {
  padding: 0;
  text-align: 'left';
  margin-top: 10px;
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
}

.author__container {
  display: grid;
  grid-template-areas:
    'image title'
    'image body'
    'image footer';
}

.author h1 {
  text-align: left;
  text-transform: none !important;
  background-image: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0px 0px 30px #3affef9a;
}

.author h2 {
  text-align: left;
  display: block;
}

.author__image {
  padding: 0 !important;
  overflow: hidden;
  grid-area: image;
  min-width: 260px;
  max-height: 100%;
  transform: translateY(0px);
}

.author__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.author__title {
  grid-area: title;
  padding: 20px 20px 0 20px;
  border-left: 10px solid rgba(0, 0, 0, 0.367);
}

.author__subtitle {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.author__insta {
  display: flex;
  align-items: center;
  gap: 7px;
}

.author__insta p {
  color: grey;
}

.author__body {
  grid-area: body;
  padding: 20px 20px 25px 20px;
  border-left: 10px solid rgba(0, 0, 0, 0.367);
  font-size: 18px;
}

.author ul {
  list-style-image: url('./assets/images/Marker_2.png');
  text-align: left;
  padding-left: 20px;
}
.author li {
  padding-top: 10px;
}

.author li:first-of-type {
  padding-top: 0;
}

.author__footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 30px;
  grid-area: footer;
  padding: 0px 20px 20px 20px;
  border-left: 10px solid rgba(0, 0, 0, 0.367);
}

.author__video {
  background-color: rgb(0, 0, 0);
  width: 100%;
  min-height: 180px;
  max-width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.author__footer p {
  text-align: right;
  max-width: 370px;
}

.author__footer p:first-child {
  font-style: italic;
  line-height: 1.2;
}

.author__footer p:last-child {
  font-weight: 900;
  margin-top: 10px;
}

/* PLAYER */

.video {
  position: absolute;
  position: relative;
  padding: 0 0 56.25% 0;
  margin-top: 10px;
  background-color: rgba(89, 89, 89, 0.266);
}

.video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
}

/* TITLE */

.title {
  text-align: center;
  padding: 40px 20px;
  margin-top: 10px;
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
}
.title h1 {
  background-image: linear-gradient(90.26deg, #3affef 3.26%, #00fe9d 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0px 0px 30px #3affef9a;
}

/* POSSIBILITIES */

.possibilities {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.possibilities div {
  width: 100%;
}

.possibilities ul {
  margin-top: 20px;
}

.possibilities li {
  line-height: 20px;
  margin-top: 10px;
}

.possibilities div:first-child {
  padding: 40px 20px;
  background: linear-gradient(
    262.16deg,
    rgba(48, 48, 48, 0) -35.7%,
    rgba(230, 119, 51, 0.24) 38.34%,
    rgba(0, 0, 0, 0.1312) 123.11%
  );
  backdrop-filter: blur(30px);
}

.possibilities div:last-child {
  padding: 40px 20px;
  background: linear-gradient(
    97.34deg,
    rgba(48, 48, 48, 0.32) -1.7%,
    rgba(250, 0, 204, 0.29) 101.19%
  );
  backdrop-filter: blur(30px);
}

.possibilities div:first-child ul {
  padding-left: 20px;
  list-style: url('./assets/images/Marker_2.png');
}

.possibilities div:last-child h1 {
  background: linear-gradient(to right, #fa3ab9 0% 40%, #b133ff 60% 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 60px #b133ffa5;
}

.possibilities div:last-child ul {
  padding-left: 20px;
  list-style: url('./assets/images/Marker_3.png');
}

.possibilities .elem_hide:nth-of-type(2) {
  transition-delay: 0.3s;
}

/* ACTIVITIES */

.activities {
  margin-top: 10px;
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
  padding: 70px 20px;
  text-align: center;
}

.activities__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 60px;
  column-gap: 100px;
  row-gap: 60px;
}

.activities__col {
  max-width: 210px;
}

.activities img {
  width: 200px;
}

.activities__col ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  list-style-type: none;
}

.activities__col p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
}

.activities__description {
  margin: 60px auto 0 auto;
  max-width: 600px;
}

.activities__description p {
  font-weight: 700;
}

.activities__description p:last-child {
  color: #0bffac;
  font-weight: 700;
  margin-top: 25px;
}

/* RESULT */

.result {
  margin-top: 10px;
}

.result h1 {
  text-transform: uppercase;
  font-weight: 900;
  padding: 40px 20px;
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
}

.result__body {
  position: relative;
}

.result__before {
  position: absolute;
  height: 100%;
  width: 40%;
  overflow: hidden;
  max-width: 100%;
}

.result__dragger {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 37%;
  top: 50%;
  transform: translateY(-50%);
  width: 55px;
}

.result__dragger img {
  width: 20px;
}

.result p {
  position: absolute;
  bottom: 0;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 35px;
}

.result p:first-of-type {
  left: 0;
}

.result p:last-of-type {
  right: 0;
}

.result__imageBefore {
  height: 99.2%;
}

.result__imageAfter {
  width: 100%;
  height: 100%;
}

/* PLAN */

.plan {
  margin-top: 10px;
  padding: 40px 20px;
  background-color: rgba(89, 89, 89, 0.266);
  backdrop-filter: blur(30px);
}

.planBlurActive {
  backdrop-filter: blur(29px);
}

.planBlurDone {
  backdrop-filter: blur(30px);
}

.plan__header {
  text-align: center;
  cursor: pointer;
}

.plan__header img {
  cursor: pointer;
  transition: 0.4s;
  margin: 0px 25px 3px 0px;
  transform: rotate(-90deg);
}
.planArrowDone {
  transform: rotate(0deg) !important;
}

.plan__header h1 {
  display: inline;
}

.plan__body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 70px;
  row-gap: 40px;
  overflow: hidden;
  max-height: 0px;
  transition: 0.4s ease-in-out;
}

.planDone {
  max-height: 1000px;
  margin-top: 40px;
}

.plan__body div {
  width: 280px;
}

.plan__body div h2 {
  font-size: 24px;
  text-align: left;
}

.plan__body div ul {
  padding-left: 20px;
  margin-top: 10px;
}

.plan__body div li {
  list-style: url('./assets/images/Marker_2.png');
  line-height: 20px;
  margin-top: 5px;
}

.elem_hide {
  opacity: 0;
  transform: translateY(50px);
  transition: 1s;
}

.elem_show {
  opacity: 1;
  transform: translateY(0px);
}

@media screen and (max-width: 1040px) {
  .intro__cover img {
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .intro__grid {
    grid-template-areas: 'cover' 'title' 'info';
  }
}

@media screen and (max-width: 900px) {
  .author__title,
  .author__body,
  .author__footer {
    border: none;
  }

  .author__footer {
    grid-area: footer;
    padding: 0px 20px 20px 20px;
  }

  .author__container {
    gap: 3px;
    grid-template-areas:
      'title'
      'image'
      'body'
      'footer';
  }

  .author__image {
    padding: 0 !important;
    overflow: hidden;
    grid-area: image;
    max-height: 100%;
    margin: 20px 20px 0 20px;
    max-height: 250px;
  }

  .author__image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: static;
  }

  .author__footer p {
    margin-top: 25px;
    margin-left: auto;
  }
}

@media screen and (max-width: 700px) {
  .plan__header img {
    width: 30px;
    margin: 0px 20px 2px 0px;
  }
  .plan__header h1 {
    margin: 15px 0px 15px 0;
    font-size: 30px;
  }
  .result__body {
    position: static;
  }
  .result__before,
  .result__after {
    position: relative;
  }
  .result__before {
    width: 100%;
    max-width: none;
  }
  .result__imageBefore {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding-top: 5px;
  }
  .result__imageAfter {
    position: static;
    width: 100%;
    height: 100%;
  }
  .result p {
    font-size: 24px;
    padding: 25px;
  }
  .result__dragger {
    display: none;
  }
  .result p:first-of-type {
    left: 0;
    top: 47%;
  }
  .result p:last-of-type {
    right: 0;
  }
  .possibilities {
    flex-wrap: wrap;
  }

  .author h1,
  .author h2 {
    text-align: center;
  }
  .author__subtitle {
    flex-direction: column;
    gap: 10px;
  }
  .author__insta {
    justify-content: center;
  }
  .author__footer {
    flex-direction: column;
    align-items: center;
  }
  .author__footer p {
    text-align: center;
  }
  .author__footer p:first-of-type {
    margin: 0;
  }
}

@media screen and (max-width: 510px) {
  .root h1 {
    font-size: 28px;
  }
  .root h2 {
    font-size: 16px;
  }
  .intro__cover {
    min-height: 220px;
  }
  .plan__header img {
    width: 27px;
    margin: 0px 15px 0px 0px;
  }
  .plan__header h1 {
    margin: 0;
    font-size: 20px;
  }
  .container {
    padding: 0px 5px;
  }
  .result p {
    font-size: 20px;
  }
  .backButton {
    top: 90px;
    left: 20px;
  }
  .intro__duration {
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .author__body {
    padding: 20px 10px 20px 10px;
  }
  .author__footer {
    padding: 0 0 20px 0;
    gap: 20px;
  }
  .author__image {
    margin: 20px 0 0 0;
  }
}

@media screen and (max-width: 410px) {
  .author__video {
    max-width: none;
  }
}
