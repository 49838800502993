.root{
    position: relative;
    overflow: hidden;
}

.slider{
    display: flex;
    width: 200vw;
    transition: .5s;
}
.sliderDone{
    transform: translateX(-50%);
}

.slotsContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    opacity: 1;
    transition: .3s;  
}

.coursesWrapper{
    width: 100%;
    position: relative;
    opacity: 1;
    transition: .3s;
}

.coursesContainer{
    margin: 70px auto 70px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
}

.containerEnterActive{
    opacity: 0;
}
.containerEnterDone{
    opacity: 0;
    height: 0;
}


@media screen and (max-width: 1333px) {
    .slotsContainer{
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}

@media screen and (max-width: 1120px) {
    .coursesContainer{
        width: 95%;
        grid-template-columns: 1fr;
        gap: 70px;
    }
}

@media screen and (max-width: 520px) {
    .slotsContainer{
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
}