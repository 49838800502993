.root{
    height: 100vh;
    position: relative;
}

.bg{
    font-family: 'Roboto';
    background-color: #000000;
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.bg img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .3s ease-out;
}

.leftSide{
    position: relative;
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
}

.logo{
    position: absolute;
    color: #ffffff;
    font-size: 24px;
    padding: 20px 0 0 20px;
    font-style: italic;
    font-weight: 700;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    z-index: 10;
}

.rightSide{
    position: relative;
    position: absolute;
    width: 55%;
    height: 100%;
    right: 0;
    z-index: 2;
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
    background-color: #121212;
}

.title{
    position: absolute;
    text-align: center;
}

.title img{
    height: 150px;
    cursor: pointer;
    transition: .3s ease-out;
}

.leftTitle{
    line-height: 100vh;
    right: 0;
    left: 0;
}

.rightTitle{
    font-family: 'Tiro Kannada', serif;
    color: #ffffff;
    position: absolute;
    top:50%;
    left: 10%;
    right: 0;
    transform:translateY(-100%);
    transition: all .4s ease-out;
}

.rightTitle h1{
    font-size: 100px;
}

.rightTitle h2{
    font-size: 4em;
    line-height: 40px;
}

.width90{
    width: 90% !important;
}

.scale{
    scale: 1.1 !important;
}


@media screen and (max-width: 1500px){
    .title img{
        height: 100px;
    }
    .rightTitle h1{
        font-size: 65px;
    }
    .rightTitle h2{
        font-size: 35px;
        line-height: 35px;
    }
}

@media screen and (max-width: 1000px){
    .title img{
        height: 70px;
    }
    .rightTitle h1{
        font-size: 45px;
    }
    .rightTitle h2{
        font-size: 25px;
        line-height: 25px;
    }
}

@media screen and (max-width: 550px){
    .title img{
        height: 50px;
    }
    .rightTitle h1{
        font-size: 35px;
    }
    .rightTitle h2{
        font-size: 20px;
        line-height: 20px;
    }
}