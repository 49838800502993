.root{
    position: relative;
}

.logo{
    position: fixed;
    z-index: 1;
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
    font-style: italic;
    font-family: 'Roboto';
    margin: 20px 20px;
}

.top{
    height: 50vh;
    width: 100vw;
    position: relative;
}
.top img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title_1{
    position: absolute;    
    width: 70%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title_1 img{
    object-fit: contain;
}

.bottom{
    height: 50vh;
    width: 100vw;
    background-color: #121212; 
    position: relative;
}

.title_2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Tiro Kannada', serif;
    color: #ffffff;
}

.title_2 h1{
    font-size: 16vw;
}

.title_2 h2{
    font-size: 8vw;
}