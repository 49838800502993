@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

*,
*:before,
*:after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, ul {
    margin: 0;
}

ul{
    padding: 0;
}

html, body {
    padding: 0; margin: 0;
}

body{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.2;
    color: #FFFFFF;
    background-color: #000000;
    overflow-x: hidden;
    overflow-anchor: none;
}

.App{
    margin: 0 auto;
    padding: 0;
}

.App::before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url('./shared/assets/BG.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.preloader{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    z-index: 1000;
    overflow: hidden;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
    width: 10px;
    background: rgba(0, 0, 0, 0.797);
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(173, 173, 173, 0.2);
    border-radius: 10px;
    height: 5px;
}

#cursor {
    position: relative;
    --size: 70px;
    position: fixed;
    top: 0;
    left: 0;
    height: var(--size);
    width: var(--size);
    background-color: rgba(0, 0, 0, 0);
    transform: translate3d(
      calc(100vw * var(--cursor-x) - var(--size) / 2), 
      calc(100vh * var(--cursor-y) - var(--size) / 2), 
      0);
    pointer-events: none;
    z-index: 1;
}
#cursor img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -30%);
    width: 70px;
    height: 70px;
    opacity: 0;
    transition: .3s ease-in-out;
}