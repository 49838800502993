.text {
  background: linear-gradient(to top, black, 60%, rgba(0, 0, 0, 0));
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px 10px 20px 10px;
  font-size: 28px;
  transform: translateY(20px);
  opacity: 0;
  transition: 0.3s ease-in-out;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);
  z-index: 10;
}
.slotTextDone {
  transform: translateY(0px);
  opacity: 1;
}

.content {
  position: relative;
  padding: 0px 0px 57% 0px;
  overflow: hidden;
  cursor: pointer;
}

.root img {
  opacity: 1;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;

  transition: 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.slotImgDone {
  opacity: 0;
}

.root video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
  opacity: 0;
}
.slotVideoDone {
  opacity: 1 !important;
}
